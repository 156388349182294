import { Component } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {AuthService} from "../../../core/services/auth/auth.service";
import {NgxSpinnerModule, NgxSpinnerService} from "ngx-spinner";
import {NgIf} from "@angular/common";
import {AuthFormLabelDirective} from "../../../shared/directives/auth/auth-form-label.directive";
import {AuthFormInputDirective} from "../../../shared/directives/auth/auth-form-input.directive";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    NgIf,
    AuthFormLabelDirective,
    AuthFormInputDirective
  ],
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent {
  loading: boolean = false;

  email: string = "";
  successMessage: string| null = null;

  constructor(private authService: AuthService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  restore(){
    this.loading = true;
    this.spinner.show();
    this.authService.sendPasswordResetLink(this.email).subscribe({
      next: result => {
        console.log(result);
        this.successMessage = 'Enlace de restablecimiento de contraseña enviado con éxito, siga las instrucciones de su correo.';
      },
      error: () => {
        this.spinner.hide();
        this.loading = false;
        this.toastr.error('Error al enviar el enlace de restablecimiento de contraseña.')
        this.successMessage = '';
      },
      complete: () => {
        this.spinner.hide();
        this.loading = false;
      },
    });
  }
}
