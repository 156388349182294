import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-dashboard-header',
  standalone: true,
  imports: [],
  templateUrl: './dashboard-header.component.html',
  styleUrl: './dashboard-header.component.css'
})
export class DashboardHeaderComponent implements OnInit{
  @Input() title: string = 'Mis turnos';
  constructor(private router: Router) {}
  ngOnInit() {
    this.updateTitle(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateTitle(event.urlAfterRedirects);
      }
    });
  }

  updateTitle(url: string) {
    if (url.includes('/works')) {
      this.title = 'Ofertas de Trabajo';
    } else if (url.includes('/my-shifts')) {
      this.title = 'Mis Turnos';
    } else if (url.includes('/profile')) {
      this.title = 'Mi Perfil';
    } else if (url.includes('/dni')) {
      this.title = 'DNI';
    } else if (url.includes('/ss')) {
      this.title = 'Seguridad Social';
    } else if (url.includes('/iban')) {
      this.title = 'IBAN';
    } else if (url.includes('/detail-work')) {
      this.title = 'Información';
    } else if (url.includes('/personal-data')) {
      this.title = 'Datos personales';
    } else if (url.includes('/contracts')) {
      this.title = 'Mis contratos';
    } else if (url.includes('/documents')) {
      this.title = 'Mis documentos';
    } else {
      this.title = '';
    }
  }
}
