import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-sign-document',
  standalone: true,
  imports: [],
  templateUrl: './sign-document.component.html',
  styleUrl: './sign-document.component.css'
})
export class SignDocumentComponent implements OnInit {
  documentId: string|undefined|null;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    // Obtener el ID del documento de los parámetros de la ruta
    this.documentId = this.route.snapshot.paramMap.get('id');
    console.log(this.documentId);  // Aquí tienes el contract.id
  }
}
