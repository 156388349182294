import {Component, OnInit} from '@angular/core';
import {AuthFormInputDirective} from "../../../../shared/directives/auth/auth-form-input.directive";
import {AuthFormLightLabelDirective} from "../../../../shared/directives/auth/auth-form-light-label.directive";
import {AuthFormSelectDirective} from "../../../../shared/directives/auth/auth-form-select.directive";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {User} from "../../../../models/User";
import {UserService} from "../../../../core/services/users/user.service";
import {AuthStateService} from "../../../../core/services/auth/auth-state.service";
import {AuthService} from "../../../../core/services/auth/auth.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-personal-data',
  standalone: true,
  imports: [
    AuthFormInputDirective,
    AuthFormLightLabelDirective,
    AuthFormSelectDirective,
    FormsModule,
    LoaderComponent,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    RouterLink,
    RouterLinkActive,
    NgClass
  ],
  templateUrl: './personal-data.component.html',
  styleUrl: './personal-data.component.css'
})
export class PersonalDataComponent implements OnInit{

  provinces: string[] = [ // Lista de provincias de España
    "Álava", "Albacete", "Alicante", "Almería", "Asturias", "Ávila",
    "Badajoz", "Baleares", "Barcelona", "Burgos", "Cáceres", "Cádiz",
    "Cantabria", "Castellón", "Ceuta", "Ciudad Real", "Córdoba", "Cuenca",
    "Girona", "Granada", "Guadalajara", "Guipúzcoa", "Huelva", "Huesca",
    "Jaén", "La Coruña", "La Rioja", "Las Palmas", "León", "Lérida",
    "Lugo", "Madrid", "Málaga", "Melilla", "Murcia", "Navarra",
    "Orense", "Palencia", "Pontevedra", "Salamanca", "Segovia",
    "Sevilla", "Soria", "Tarragona", "Santa Cruz de Tenerife",
    "Teruel", "Toledo", "Valencia", "Valladolid", "Vizcaya",
    "Zamora", "Zaragoza"
  ];
  showPassword: boolean = false;
  loading: boolean = false;
  currentUser: User | null = null;
  id: number | null = null;
  name: string = "";
  email: string = "";
  password: string = "";
  address: string = "";
  personalDataForm:FormGroup;

  constructor(
    private userService: UserService,
    private authStateService: AuthStateService,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) {
    this.personalDataForm = this.fb.group({
      name: [{
        value:'',
      }],
      email:[{
        value:'',
      }],
      phone: [{
        value:'',
      }],
      address:[{
        value:'',
      }],
      province: [{
        value:'',
      }],
      ss_number: [{
        value:'',
      }],
      iban: [{
        value:'',
      }],
      password: [{
        value:' ',
      }],
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.currentUser = this.authStateService.currentUserValue;
    if (this.currentUser && this.currentUser.profile) {

      this.personalDataForm.patchValue({
        name: this.currentUser.profile.name,
        email: this.currentUser.profile.email,
        phone: this.currentUser.profile.phone,
        address: this.currentUser.profile.address,
        province: this.currentUser.profile.province,
        dni: this.currentUser.profile.dni,
        ss_number: this.currentUser.profile.ss_number,
        iban: this.currentUser.profile.iban,
        password: '',
      })
    }

    this.authStateService.profileUpdated.subscribe(() => {
      this.updateDniInProfile();  // Método para actualizar la información del perfil con el nuevo DNI
    });
  }

  updateDniInProfile() {
    const updatedUser = this.authStateService.currentUserValue;
    if (updatedUser && updatedUser.profile) {
      this.personalDataForm.patchValue({
        dni: updatedUser.profile.dni,
      });
    }
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }


  saveClick() {
    const data: any = {
      user_id:this.currentUser?.profile.id,
      name:this.personalDataForm.get('name')?.value,
      email:this.personalDataForm.get('email')?.value,
      phone: this.personalDataForm.get('phone')?.value,
      address: this.personalDataForm.get('address')?.value,
      province: this.personalDataForm.get('province')?.value,
      dni: this.personalDataForm.get('dni')?.value,
      ss_number: this.personalDataForm.get('ss_number')?.value,
      iban: this.personalDataForm.get('iban')?.value,
    };

    if (this.personalDataForm.get('password')?.value != '') {
      data.password = this.personalDataForm.get('password')?.value;
    }

    this.userService.updateUser(data).subscribe({
      next: (data) => {
        this.authStateService.setProfile(data.user);
        this.toastr.success('Usuario actualizado con éxito.');
      }, error: () => {
        this.toastr.error('Error al actualizar el usuario');
      }
    });
  }

  checkChanges(){
    if(this.currentUser && this.personalDataForm){
      return (
        this.personalDataForm.get('dni')?.value !== this.currentUser.profile.dni ||
        this.personalDataForm.get('name')?.value !== this.currentUser.profile.name ||
        this.personalDataForm.get('email')?.value !== this.currentUser.profile.email ||
        this.personalDataForm.get('phone')?.value !== this.currentUser.profile.phone ||
        this.personalDataForm.get('address')?.value !== this.currentUser.profile.address ||
        this.personalDataForm.get('province')?.value !== this.currentUser.profile.province ||
        this.personalDataForm.get('name')?.value !== this.currentUser.profile.name ||
        this.personalDataForm.get('iban')?.value !== this.currentUser.profile.iban ||
        this.personalDataForm.get('ss_number')?.value !== this.currentUser.profile.ss_number ||
        this.personalDataForm.get('password')?.value !== ''
      );
    }
    return false;
  }

}
