import { Component } from '@angular/core';

@Component({
  selector: 'app-documents-to-sign',
  standalone: true,
  imports: [],
  templateUrl: './documents-to-sign.component.html',
  styleUrl: './documents-to-sign.component.css'
})
export class DocumentsToSignComponent {

}
