<footer class="bg-bg-dark text-gray-light p-1 text-center">
  <nav>
    <ul class="list-none flex justify-around">
      <li class="text-center my-4 cursor-pointer" routerLink="/dashboard/works">
        <div class="flex justify-center mb-1">
          <svg *ngIf="icon !== 'works'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="26" height="26" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-51.2 -51.2 614.40 614.40" enable-background="new 0 0 512 512" xml:space="preserve" fill="#FCFC" stroke="#FCFC"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-51.2" y="-51.2" width="614.40" height="614.40" rx="307.2" fill="#242424" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path fill="#FCFCFC" d="M302.188,279.563C313.36,263.875,320,244.727,320,224c0-53.032-42.984-96-96-96c-53.017,0-96,42.968-96,96 c0,53.016,42.983,96,96,96c20.727,0,39.875-6.64,55.563-17.812l77.124,77.124c6.25,6.252,16.375,6.252,22.624,0 c6.252-6.249,6.252-16.374,0-22.623L302.188,279.563z M278.953,256.319c-5.53,9.36-13.273,17.104-22.634,22.634 C246.805,284.563,235.843,288,224,288c-35.345,0-64-28.656-64-64s28.655-64,64-64c35.344,0,64,28.656,64,64 C288,235.843,284.563,246.805,278.953,256.319z M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256 S397.391,0,256,0z M256,472c-119.298,0-216-96.702-216-216c0-119.297,96.702-216,216-216s216,96.703,216,216 C472,375.298,375.298,472,256,472z"></path> </g> </g> </g></svg>
          <svg *ngIf="icon === 'works'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="26" height="26" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-51.2 -51.2 614.40 614.40" enable-background="new 0 0 512 512" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="4"><rect x="-51.2" y="-51.2" width="614.40" height="614.40" rx="307.2" fill="#00FF3B" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path fill="#242424" d="M302.188,279.563C313.36,263.875,320,244.727,320,224c0-53.032-42.984-96-96-96c-53.017,0-96,42.968-96,96 c0,53.016,42.983,96,96,96c20.727,0,39.875-6.64,55.563-17.812l77.124,77.124c6.25,6.252,16.375,6.252,22.624,0 c6.252-6.249,6.252-16.374,0-22.623L302.188,279.563z M278.953,256.319c-5.53,9.36-13.273,17.104-22.634,22.634 C246.805,284.563,235.843,288,224,288c-35.345,0-64-28.656-64-64s28.655-64,64-64c35.344,0,64,28.656,64,64 C288,235.843,284.563,246.805,278.953,256.319z M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256 S397.391,0,256,0z M256,472c-119.298,0-216-96.702-216-216c0-119.297,96.702-216,216-216s216,96.703,216,216 C472,375.298,375.298,472,256,472z"></path> </g> </g> </g></svg>
        </div>
        <a routerLink="/dashboard/works" routerLinkActive="active">Trabajos</a>
      </li>
      <li class="text-center my-4 cursor-pointer"  routerLink="/dashboard/my-shifts">
        <div class="flex justify-center mb-1">
          <svg *ngIf="icon === 'shifts'" width="26px" height="26px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#242424"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#00FF3B" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 9H21M17 13.0014L7 13M10.3333 17.0005L7 17M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#000000" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
          <svg *ngIf="icon !== 'shifts'" width="26px" height="26px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#242424"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#242424" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 9H21M17 13.0014L7 13M10.3333 17.0005L7 17M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#fcfcfc" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
        </div>
        <a routerLink="/dashboard/my-shifts" routerLinkActive="active">Mis Turnos</a>
      </li>
      <li class="text-center my-4 cursor-pointer" routerLink="/dashboard/profile">
        <div class="flex justify-center mb-1">
          <svg *ngIf="icon === 'profile'" width="26px" height="26px" viewBox="-4.8 -4.8 33.60 33.60" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-4.8" y="-4.8" width="33.60" height="33.60" rx="16.8" fill="#00FF3B" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#000000" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" stroke="#000000" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
          <svg *ngIf="icon !== 'profile'" width="26px" height="26px" viewBox="-4.8 -4.8 33.60 33.60" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-4.8" y="-4.8" width="33.60" height="33.60" rx="16.8" fill="#242424" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#fcfcfc" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" stroke="#fcfcfc" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
        </div>
        <a routerLink="/dashboard/profile" routerLinkActive="active">Mi Perfil</a>
      </li>
    </ul>
  </nav>
</footer>
