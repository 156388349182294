<section class="font-roboto bg-bg-dark animate-fade-in">
  <div class="px-4 py-8 mx-auto h-screen lg:py-0">
    <div class="w-full md:mt-0 sm:max-w-lg xl:p-0 animate-fade-in-up">
      <div class="pb-16 space-y-4 md:space-y-6 sm:p-8">
        <form (ngSubmit)="saveClick()" [formGroup]="profileForm" class="flex flex-col gap-4 pb-4">
          <div class="bg-dark-gray p-2 rounded-full min-h-72">
            <div>
              <span class="text-gray-light mb-2">Foto de perfil</span>
              <hr class="w-full text-secondaryGray mb-4">
            </div>
            <!-- Mostrar el loader para la imagen delantera si loading es verdadero -->
            <div *ngIf="loading" class="flex justify-center items-center h-36">
              <app-loader></app-loader>
            </div>
            <div *ngIf="!loading">
              <div class="flex justify-center relative" *ngIf="profileImage; else uploadImage">
                <div class="w-[200px] h-[200px] rounded-full overflow-hidden mb-4">
                  <!-- Imagen ajustada al contenedor circular -->
                  <img [src]="profileImage" alt="Parte Delantera del Documento" class="w-full h-full object-contain brightness-100">
                </div>                <svg (click)="deleteImage()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 text-primary cursor-pointer right-1 top-1 absolute drop-shadow-2xl">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M18 6l-12 12" />
                  <path d="M6 6l12 12" />
                </svg>
              </div>

              <!-- Mostrar opción de subida si no hay imagen -->
              <ng-template #uploadImage class="w-full">
                <div class="flex justify-center">
                  <label for="front-file-upload" class="cursor-pointer flex flex-col justify-center items-center w-36 h-36 bg-secondaryGray rounded-full hover:bg-gray-600 transition duration-300">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mb-2 w-10 h-10 text-gray-light">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                      <path d="M9 13a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                    </svg>
                    <span class="text-gray-light">Subir foto</span>
                    <input id="front-file-upload" type="file" (change)="onFileChange($event, 'profile')" class="hidden"/>
                  </label>
                </div>
              </ng-template>
            </div>
          </div>
          <div>
            <div class="flex justify-between items-center min-h-14 px-2 bg-gray-700 rounded-t-lg border-b-2 border-lightGray text-gray-light">
              <span class="text-gray-light">DNI</span>
              <input class="bg-gray-700 text-center" formControlName="dni" type="text" name="phone" id="dni" placeholder="DNI" required>
              <div class="flex">
              <span class=" flex justify-center items-center">
                <a *ngIf="!dniForm && !loadingDni" routerLink="/dashboard/dni" routerLinkActive="active" class="pr-5 text-3xl ">+</a>
                <a *ngIf="dniForm && !loadingDni" routerLink="/dashboard/dni" routerLinkActive="active" class="pr-2 text-2xl ">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                       stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-primary w-8 h-8"><path
                    stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l5 5l10 -10"/>
                  </svg>
                </a>
              <app-loader class="pr-4" *ngIf="loadingDni" size="small"></app-loader>
              </span>
              </div>
            </div>
            <div class="flex justify-between items-center min-h-14 px-2 bg-gray-700 border-b-2 border-lightGray text-gray-light">
              <span class="text-gray-light">Datos personales</span>
              <a routerLink="/dashboard/personal-data" routerLinkActive="active" class="pr-1 text-2xl">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#00FF3B" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M5 12l14 0" />
                  <path d="M15 16l4 -4" />
                  <path d="M15 8l4 4" />
                </svg>
              </a>
            </div>
            <div class="flex justify-between items-center min-h-14 px-2 bg-gray-700 border-b-2 border-lightGray text-gray-light">
              <span class="text-gray-light">Contratos</span>
              <a routerLink="/dashboard/contracts" routerLinkActive="active" class="pr-1 text-2xl">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#00FF3B" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M5 12l14 0" />
                  <path d="M15 16l4 -4" />
                  <path d="M15 8l4 4" />
                </svg>
              </a>
            </div>
            <div class="flex justify-between items-center min-h-14 px-2 bg-gray-700 rounded-b-lg text-gray-light">
              <span class="text-gray-light">Documentos</span>
              <a routerLink="/dashboard/documents" routerLinkActive="active" class="pr-1 text-2xl">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#00FF3B" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M5 12l14 0" />
                  <path d="M15 16l4 -4" />
                  <path d="M15 8l4 4" />
                </svg>
              </a>
            </div>
          </div>
        </form>
        <div class="flex justify-center mb-16">
          <button class="text-primary" (click)="logOut()">Cerrar sesión</button>
        </div>
      </div>
    </div>
  </div>
</section>
