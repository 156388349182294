import { Directive } from '@angular/core';

@Directive({
  selector: '[appAuthFormSelect]',
  host: {
    'class': 'border-b border-lightGray bg-gray-dark outline-none text-gray-light w-full font-semibold block p-2.5'
  },
  standalone: true
})
export class AuthFormSelectDirective {

  constructor() { }

}
