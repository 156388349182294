import { Component } from '@angular/core';
import {AuthService} from "../../../core/services/auth/auth.service";
import {Response} from "../../../models/Response";
import {User} from "../../../models/User";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthStateService} from "../../../core/services/auth/auth-state.service";
import {Router, RouterLink} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {AuthFormInputDirective} from "../../../shared/directives/auth/auth-form-input.directive";
import {AuthFormLabelDirective} from "../../../shared/directives/auth/auth-form-label.directive";
import {ToastrService} from "ngx-toastr";
import {NgIf} from "@angular/common";
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    RouterLink,
    AuthFormInputDirective,
    AuthFormLabelDirective,
    ReactiveFormsModule,
    NgIf,
  ],
  templateUrl: './login.component.html'
})
export class LoginComponent {
  loading: boolean = false;
  showPassword: boolean = false;
  email: string = "";
  password: string = "";

  constructor(private authService: AuthService, private authStateService: AuthStateService, private router: Router,
              private spinner: NgxSpinnerService,private toast: ToastrService) {
    console.log(this.authStateService.isLoggedIn());
  }

  loginClick() {
    this.loadingFunc(true);
    this.authService.signIn({
      email: this.email,
      password: this.password
    }).subscribe({
      next: (data: Response<User>) => {
        if (data.data) {
          this.authStateService.setUser(data.data);
          console.log(data.data);
          this.router.navigate(['/dashboard/my-shifts']);
        } else {
          this.authStateService.setUser(null);
        }
      },
      error: (data) => {
        this.toast.error(data.error.message);
        this.loadingFunc(false);
      },
      complete: () => {
        this.loadingFunc(false);
      },
    })
  }

  loadingFunc(loading: boolean) {
    this.loading = loading;
    loading ? this.spinner.show() : this.spinner.hide();
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
}
