<section class="font-roboto bg-bg-dark animate-fade-in">
  <div class="px-4 py-8 mx-auto h-screen lg:py-0">
    <div class="w-full md:mt-0 sm:max-w-lg xl:p-0 animate-fade-in-up">
      <div class="pb-16 space-y-4 md:space-y-6 sm:p-8">
        <form (ngSubmit)="saveClick()" [formGroup]="personalDataForm" class="flex flex-col gap-4 pb-4">
          <div class="mb-4">
            <span appAuthFormLightLabel>Nombre</span>
            <input formControlName="name" type="text" name="name" id="name" appAuthFormInput placeholder="Nombre"
                   required="">
          </div>
          <div class="mb-10">
            <span appAuthFormLightLabel>Email</span>
            <input formControlName="email" type="email" name="email" id="email" appAuthFormInput placeholder="Email"
                   required="">
          </div>
          <div class="mb-10">
            <span appAuthFormLightLabel>Teléfono</span>
            <input formControlName="phone" type="text" name="phone" id="phone" appAuthFormInput placeholder="Teléfono"
                   required="">
          </div>
          <div class="mb-4">
            <span appAuthFormLightLabel>Dirección</span>
            <input formControlName="address" type="text" name="address" id="address" appAuthFormInput
                   placeholder="Dirección" required="">
          </div>
          <div class="mb-10">
            <span appAuthFormLightLabel>Provincia</span>
            <select formControlName="province" type="text" name="province" id="province" appAuthFormSelect required>
              <option value="">Provincia</option>
              <option *ngFor="let province of provinces" [value]="province">{{ province }}</option>
            </select>
          </div>
          <div>
            <span appAuthFormLightLabel>Nº Seguridad Social</span>
            <input formControlName="ss_number" type="text" name="ss_number" id="ss_number" appAuthFormInput
                   placeholder="Numero de la Seguridad Social" required>
          </div>
          <div class="mb-10">
            <span appAuthFormLightLabel>IBAN</span>
            <input formControlName="iban" type="text" name="Iban" id="iban" appAuthFormInput
                   placeholder="IBAN" required>
          </div>
          <div>
            <span appAuthFormLightLabel>Nueva contraseña</span>
            <div class="flex mb-10">
              <input formControlName="password" [type]="showPassword ? 'text' : 'password'" name="password" id="password"
                     placeholder="Cambiar contraseña" appAuthFormInput required="">
              <span (click)="togglePasswordVisibility()"
                    class="px-2 border-b border-lightGray outline-none text-gray-light flex justify-center items-center">
              <svg width="24px" height="24px" viewBox="-2.4 -2.4 28.80 28.80" fill="none"
                   xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" transform="matrix(1, 0, 0, 1, 0, 0)"><path
                d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path
                d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            </span>
            </div>
          </div>
          <button [disabled]="!checkChanges()" type="submit" [ngClass]="{
          'bg-primary text-white': checkChanges(),
          'bg-bg-dark text-gray-light': !checkChanges()
        }"
                  class="mb-4 w-full border-2 border-gray-light text-gray-light hover:opacity-85 focus:ring-4 focus:outline-none focus:ring-primary rounded-xl text-md px-5 py-3 text-center ">
            Guardar
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
