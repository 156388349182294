import {Component, OnInit} from '@angular/core';
import {UserService} from "../../../../core/services/users/user.service";
import {AuthStateService} from "../../../../core/services/auth/auth-state.service";
import {ToastrService} from "ngx-toastr";
import {User} from "../../../../models/User";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import {Media} from "../../../../models/Media";
import {Router} from "@angular/router";

@Component({
  selector: 'app-contracts',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    JsonPipe,
  ],
  templateUrl: './contracts.component.html',
  styleUrl: './contracts.component.css'
})
export class ContractsComponent implements OnInit{
  currentUser: User | null = null;
  contracts: Media[] = [];

  constructor(private userService: UserService, private authStateService: AuthStateService, private toastr: ToastrService,
              private router: Router) {}

  ngOnInit() {
    this.currentUser = this.authStateService.currentUserValue;
    this.fetchContracts();
  }

  fetchContracts(){
    if(this.currentUser && this.currentUser.profile){
      this.userService.getContracts(this.currentUser.profile.id).subscribe({
        next: (data) => {
          if (data.success) {
            this.contracts = data.data;
          }
        },
        error: (err) => {
          this.toastr.error('Error al obtener los contratos');
        },
        complete: () => {
          //console.log(this.contracts);
        }
      });
    }
  }

  getCompanyName(contract: Media): string {
    const companyName = contract.custom_properties['company'];
    return companyName ? companyName.replace(/-/g, ' ') : 'Desconocido';
  }

  viewContract(contract: Media): void {
    window.open(contract.original_url, '_blank');
  }

  checkIsSigned(contract: Media): boolean {
    const isSigned = contract.custom_properties['signed'] === true;
    if (isSigned) {
      console.log('Contract properties:', contract.custom_properties);
      return true;
    }

    return false;
  }

  // Método para navegar al componente de firma con el ID del contrato
  signDocument(contractId: number) {
    console.log(contractId);
    this.router.navigate([`/dashboard/sign-contract/`, contractId]);
  }
}
