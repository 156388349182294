import {Component, Input, OnInit} from '@angular/core';
import {Shift} from "../../models/Shift";
import {Tag, Work, Event} from "../../models/Work";
import {NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-work-card',
  standalone: true,
  imports: [
    NgIf,
    NgForOf
  ],
  templateUrl: './work-card.component.html',
  styleUrl: './work-card.component.css'
})
export class WorkCardComponent implements OnInit{
  @Input() work!: Work;
  web_job!: string;
  workplace!: string;
  location!: string;
  salary!: number;
  tags!: Tag[];
  start_date: string | null = null;
  end_date: string |null = null;
  freeSlots: number = 0;
  event!: Event;

  ngOnInit(){
    this.workplace = this.work.workplace.name;
    this.web_job = this.work.web_job.name;
    this.location = this.work.workplace.location;
    this.salary = this.work.salary;
    this.tags = this.work.tags;
    this.event = this.work.event;

    this.work.free_shifts.forEach((shift) => {
      if (shift.freeSlots) {
        this.freeSlots += shift.freeSlots;
      }

      const startDate = new Date(shift.start_hour).toISOString().split('T')[0];
      const endDate = new Date(shift.end_hour).toISOString().split('T')[0];

      if (this.start_date === null || startDate < this.start_date) {
        this.start_date = startDate;
      }

      if (this.end_date === null || endDate > this.end_date) {
        this.end_date = endDate;
      }
    });

    this.start_date = this.start_date ? this.formatDate(this.start_date) : 'No disponible';
    this.end_date = this.end_date ? this.formatDate(this.end_date) : 'No disponible';
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);

    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const dayOfMonth = date.getDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];

    return `${dayOfMonth} de ${month}`;
  }
}
