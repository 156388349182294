import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthFormInputDirective} from "../../../../shared/directives/auth/auth-form-input.directive";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {User} from "../../../../models/User";
import {ShiftService} from "../../../../core/services/shifts/shift.service";
import {AuthStateService} from "../../../../core/services/auth/auth-state.service";
import {UserService} from "../../../../core/services/users/user.service";
import {ToastrService} from "ngx-toastr";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";
import {forkJoin} from "rxjs";
import {AuthFormLabelDirective} from "../../../../shared/directives/auth/auth-form-label.directive";
import {AuthFormLightLabelDirective} from "../../../../shared/directives/auth/auth-form-light-label.directive";
import {AuthFormSelectDirective} from "../../../../shared/directives/auth/auth-form-select.directive";
import {AuthService} from "../../../../core/services/auth/auth.service";

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    AuthFormInputDirective,
    FormsModule,
    NgForOf,
    ReactiveFormsModule,
    RouterLink,
    RouterLinkActive,
    NgIf,
    LoaderComponent,
    AuthFormLabelDirective,
    AuthFormLightLabelDirective,
    AuthFormSelectDirective,
    NgClass
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})

export class ProfileComponent implements OnInit{
  loading: boolean = false;
  loadingDni :boolean = false;
  currentUser: User | null = null;
  dniForm: boolean = false;
  profileForm:FormGroup;
  profileImage: any;
  frontImage: any;
  backImage: any;

  constructor(
    private userService: UserService,
    private authStateService: AuthStateService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) {
    this.profileForm = this.fb.group({
      dni: [{
        value:'',
      }]
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.currentUser = this.authStateService.currentUserValue;
    if (this.currentUser && this.currentUser.profile) {

      this.profileForm.patchValue({
        dni: this.currentUser.profile.dni,
      })
    }

    this.fetchImages();
    this.fetchProfileImage();

    this.authStateService.profileUpdated.subscribe(() => {
      this.updateDniInProfile();  // Método para actualizar la información del perfil con el nuevo DNI
    });
  }

  deleteImage() {
    if (this.currentUser && this.currentUser.profile && this.currentUser.profile.media) {
      // Filtra la imagen frontal del DNI
      const profileMediaIndex = this.currentUser.profile.media.findIndex((mediaItem: any) => mediaItem.collection_name === 'profile-image');

      if (profileMediaIndex !== -1) {

        this.userService.deleteProfileImage(this.currentUser.profile.id).subscribe({
          next: (data) => {
            if (data.success) {
              // Eliminar la imagen del array `media`
              this.currentUser!.profile.media.splice(profileMediaIndex, 1);
              this.profileImage = null; // Limpia la imagen mostrada
              this.toastr.success(data.message);
            } else {
              this.toastr.error(data.message);
            }
          },
          error: (err) => {
            this.toastr.error(err.error.message);
          },
          complete: () => {
            console.log(this.currentUser?.profile);
          }
        });
      } else {
        this.toastr.warning('No se encontró la imagen de perfil para eliminar.');
      }
    } else {
      this.toastr.error('No se pudo cargar el perfil del usuario.');
    }
  }

  onFileChange(event: any, type: 'profile') {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      if (file) {
        this.loading = true;

        this.userService.uploadProfileImage(file, type).subscribe({
          next: () => {
            this.toastr.success('Subido correctamente');
            this.fetchProfileImage()
          },
          error: (err) => {
            this.loading = false;
            this.toastr.error('Error al subir');
          },
          complete: () => {
            this.loading = false;
          }
        });
      }
    }
  }

  fetchProfileImage(){
    this.loading = true;

    if (this.currentUser && this.currentUser.profile && this.currentUser.profile.media) {
      // Filtra la colección para encontrar la imagen frontal del DNI
      const profileMedia = this.currentUser.profile.media.find((mediaItem: any) => mediaItem.collection_name === 'profile-image');

      if (profileMedia) {
        this.profileImage = profileMedia.original_url;
        console.log(this.currentUser.profile);
      } else {
        this.userService.getProfileImage(this.currentUser.profile.id).subscribe({
          next: (data) => {
            if (data.success && data.data) {
              this.profileImage = data.data.original_url;
              this.currentUser!.profile.media.push(data.data);
            }
          },
          error: (err) => {
            this.toastr.error('Error al obtener la imagen de perfil');
          },
          complete: () => {
            this.loading = false;
          }
        });
      }
    } else {
      this.toastr.error('No se pudo cargar el perfil del usuario.');
    }
    this.loading = false;
  }

  /*fetchProfileImage(){
    this.loading = true;
    if(this.currentUser && this.currentUser.profile){
      console.log(this.currentUser.profile);

      this.userService.getProfileImage(this.currentUser.profile.id).subscribe({
        next: (data) => {
          if (data.success) {
            this.profileImage = data.data;
          }
        },
        error: (err) => {
          this.toastr.error('Error al obtener la imagen delantera');
        },
        complete: () => {
          this.loading = false;
        }
      });
    }
  }*/

  updateDniInProfile() {
    const updatedUser = this.authStateService.currentUserValue;
    if (updatedUser && updatedUser.profile) {
      this.profileForm.patchValue({
        dni: updatedUser.profile.dni,
      });
    }
  }

  saveClick() {
    const data: any = {
      user_id:this.currentUser?.profile.id,
      name:this.profileForm.get('name')?.value,
      email:this.profileForm.get('email')?.value,
      phone: this.profileForm.get('phone')?.value,
      address: this.profileForm.get('address')?.value,
      province: this.profileForm.get('province')?.value,
      dni: this.profileForm.get('dni')?.value,
      ss_number: this.profileForm.get('ss_number')?.value,
      iban: this.profileForm.get('iban')?.value,
    };

    if (this.profileForm.get('password')?.value != '') {
      data.password = this.profileForm.get('password')?.value;
    }

    this.userService.updateUser(data).subscribe({
      next: (data) => {
        this.authStateService.setProfile(data.user);
        this.toastr.success('Usuario actualizado con éxito.');
      }, error: () => {
        this.toastr.error('Error al actualizar el usuario');
      }
    });
  }

  fetchImages() {
    this.loadingDni = true;

    if (this.currentUser && this.currentUser.profile) {
      // Usa forkJoin para esperar que ambas peticiones se completen
      forkJoin({
        front: this.userService.getFront(this.currentUser.profile.id),
        back: this.userService.getBack(this.currentUser.profile.id)
      }).subscribe({
        next: (results) => {
          if (results.front.success) {
            this.frontImage = results.front.data.original_url;
          } else {
            //this.toastr.error('Error al obtener la imagen delantera');
          }

          if (results.back.success) {
            this.backImage = results.back.data.original_url;
          } else {
            //this.toastr.error('Error al obtener la imagen trasera');
          }

          this.checkDni(); // Ahora llama a checkDni después de que ambas imágenes se hayan cargado
        },
        error: (err) => {
          this.toastr.error('Error al obtener las imágenes');
        },
        complete: () => {
          this.loadingDni = false;
        }
      });
    } else {
      this.loadingDni = false;
    }
  }

  checkDni() {
    if (this.currentUser) {
      if (this.currentUser.profile.dni && this.currentUser.profile.dni_expiration && this.frontImage && this.backImage) {
        this.dniForm = true;
      } else {
        this.toastr.info('Complete todos los datos del DNI')
        this.dniForm = false;
      }
    }
  }

  logOut(){
    this.authService.logOut().subscribe({
      next: value => {
        this.authStateService.setUser(null);
        this.router.navigate(['/login'])
      },error: () => {
        this.authStateService.setUser(null);
        this.router.navigate(['/login'])
      }
    })
  }
}
