import {Component, OnInit} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {ShiftCardComponent} from "../../../../cards/shift-card/shift-card.component";
import {WorkCardComponent} from "../../../../cards/work-card/work-card.component";
import {WebJob, Work} from "../../../../models/Work";
import {WorkService} from "../../../../core/services/works/work.service";
import {Response} from "../../../../models/Response";
import {WorkFilterComponent} from "./work-filter/work-filter.component";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";
import {User} from "../../../../models/User";
import {AuthStateService} from "../../../../core/services/auth/auth-state.service";

@Component({
  selector: 'app-works',
  standalone: true,
    imports: [
        NgForOf,
        NgIf,
        ShiftCardComponent,
        WorkCardComponent,
        WorkFilterComponent,
        LoaderComponent
    ],
  templateUrl: './works.component.html',
  styleUrl: './works.component.css'
})
export class WorksComponent implements OnInit{
  works!: Work[] | undefined;
  loading:boolean = false;
  isDropdownOpen: boolean = false;
  currentUser: User | null = null;
  currentTitle: string = '';

  constructor(
    private workService: WorkService,
    private authStateService: AuthStateService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.currentUser = this.authStateService.currentUserValue;

    // Cargamos los trabajos sin filtro al inicio
    this.loadWorks();
  }

  // Función para cargar trabajos con o sin filtros
  loadWorks(): void {
    this.loading = true;
    this.workService.getFreeWorks().subscribe({
      next: (response: Response<Work[]>) => {
        this.works = response.data;
      },
      error: (err) => {
        console.error('Error al obtener los trabajos:', err);
      },
      complete: () => {
        this.loading = false;
      }
    });
    this.isDropdownOpen = false;
    this.currentTitle = '';
  }

  loadWorkApplications(): void {
    this.loading = true;
    this.workService.getWorkApplications(this.currentUser!.profile.id).subscribe({
      next: (response: Response<Work[]>) => {
        this.works = response.data;
      },
      error: (err) => {
        console.error('Error al obtener los trabajos:', err);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
    this.isDropdownOpen = false;
    this.currentTitle = 'Mis inscripciones';
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onFilterChanged(filter: { webJob: WebJob | null }): void {
    this.loading = true;
    if (filter.webJob !== null) {
      this.workService.getWebJobFreeWorks(filter.webJob.id).subscribe({
        next: (response: Response<Work[]>) => {
          this.works = response.data;
        },
        error: (err) => {
          console.error('Error al obtener los trabajos:', err);
        },
        complete: () => {
          this.loading = false;
        }
      });
    } else {
      this.loading = false;
      this.loadWorks();
    }
  }
}
