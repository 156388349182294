<div class="flex flex-col bg-bg-dark">
  <div class="fixed top-0 w-full">
    <app-dashboard-header ></app-dashboard-header>
  </div>

  <main class="flex-grow overflow-y-auto bg-bg-dark h-max min-h-screen my-16 pb-12">
    <router-outlet></router-outlet>



  </main>

  <app-dashboard-navbar class="fixed bottom-0 w-full"></app-dashboard-navbar>
</div>
