import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import {AuthStateService} from "../services/auth/auth-state.service";

@Injectable()
export class AddBearerTokenInterceptor implements HttpInterceptor {
  constructor(private authStateService: AuthStateService) { }

  /**
   * Interceptor para añadir el token del usuario autenticado en cada request que se haga.
   *
   * Añade a todas las peticiones que se hagan desde la app el Bearer token del usuario si este está autenticado.
   *
   * @param req Request completa.
   * @param next Continuar con la petición.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.authStateService.isLoggedIn()) {
      const accessToken = this.authStateService.currentUserValue?.token;

      req = req.clone({
        setHeaders: {
          Authorization: "Bearer " + accessToken,
          "X-Requested-With": "XMLHttpRequest"
        }
      });
    }
    return next.handle(req);
  }
}
