import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgIf} from "@angular/common";
import {Shift} from "../../models/Shift";
import {User} from "../../models/User";
import {ShiftService} from "../../core/services/shifts/shift.service";
import {AuthStateService} from "../../core/services/auth/auth-state.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-next-shift-card',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './next-shift-card.component.html',
  styleUrl: './next-shift-card.component.css'
})
export class NextShiftCardComponent implements OnInit{
  @Input() shift!: Shift;
  @Output() shiftUpdated = new EventEmitter<void>();
  date!: string;
  start_hour!: string;
  end_hour!: string;
  web_job!: string;
  location!: string;
  workplace!: string;
  buttonLabel!: string;
  currentUser: User | null = null;
  shiftStatus!: string;
  safeTimeToCheckEnter: number = 0.5; // Margen de tiempo de seguridad para poder fichar. Solo se podrá fichar si la hora actual y la hora de inicio es menor a este valor en horas.

  constructor(
    private shiftService: ShiftService,
    private authStateService: AuthStateService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.currentUser = this.authStateService.currentUserValue;

    const startDate = new Date(this.shift.start_hour);
    const endDate = new Date(this.shift.end_hour);

    this.date = this.formatDate(startDate);

    this.start_hour = this.formatTime(startDate);
    this.end_hour = this.formatTime(endDate);

    this.workplace = this.shift.work.workplace.name;
    this.web_job = this.shift.work.web_job.name;
    this.location = this.shift.work.workplace.location;
    this.shiftStatus = this.shift.status;

    if (this.shiftStatus === 'accepted') {
      this.buttonLabel = 'Fichar entrada';
    } else if (this.shiftStatus === 'working') {
      this.buttonLabel = 'Fichar salida';
    } else {
      this.buttonLabel = 'Fichar';
    }
  }

  formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  formatDate(date: Date): string {
    // Arrays de días de la semana y meses en español
    const daysOfWeek = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
    const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

    // Obtener el día de la semana y el mes
    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];

    // Construir el string formateado
    return `${dayOfWeek} ${dayOfMonth} de ${month}`;
  }

  toggleShift() {
    const currentTime = new Date();
    const [startHour, startMinute] = this.start_hour.split(':').map(Number);
    // Creando un objeto Date para la hora de inicio con la misma fecha que la hora actual
    const startTime = new Date(currentTime);
    startTime.setHours(startHour, startMinute, 0, 0);
    // Calculando la diferencia en milisegundos
    const diff = Math.abs(currentTime.getTime() - startTime.getTime());

    if (this.shiftStatus === 'accepted' && diff <= (this.safeTimeToCheckEnter*3600000)) {
      this.shiftService.checkEnterShift(this.currentUser!.profile.id, this.shift.id).subscribe(response => {
        if (response.success) {
          this.buttonLabel = 'Fichar salida';
          this.shiftStatus = 'working'; // Actualizar estado del turno
        } else {
          this.toastr.error(response.message);
        }
      });
    } else if (this.shiftStatus === 'working') {
      this.shiftService.checkExitShift(this.currentUser!.profile.id, this.shift.id).subscribe(response => {
        if (response.success) {
          this.shiftUpdated.emit();
        }else {
          this.toastr.error(response.message);
        }
      });
    } else {
      this.toastr.info('Aun no puede fichar')
    }
  }
}
