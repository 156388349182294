import { Component } from '@angular/core';
import {AuthFormInputDirective} from "../../../shared/directives/auth/auth-form-input.directive";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Router, RouterLink} from "@angular/router";
import {NgForOf, NgIf} from "@angular/common";
import {UserService} from "../../../core/services/users/user.service";
import {ToastrService} from "ngx-toastr";
import {Response} from "../../../models/Response";
import {User} from "../../../models/User";
import {AuthService} from "../../../core/services/auth/auth.service";
import {AuthStateService} from "../../../core/services/auth/auth-state.service";
import {AuthFormLightLabelDirective} from "../../../shared/directives/auth/auth-form-light-label.directive";

@Component({
  selector: 'app-register',
  standalone: true,
    imports: [
        AuthFormInputDirective,
        FormsModule,
        RouterLink,
        NgForOf,
        ReactiveFormsModule,
        NgIf,
        AuthFormLightLabelDirective
    ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.css'
})
export class RegisterComponent {
  showPassword1: boolean = false;
  showPassword2: boolean = false;
  loadingCreate: boolean = false;

  registerForm:FormGroup;

  constructor(
    private userService:UserService,
    private toastr:ToastrService,
    private authService:AuthService,
    private authStateService:AuthStateService,
    private router:Router
    ) {
    this.registerForm = new FormGroup({
      name: new FormControl('',[Validators.required,Validators.minLength(3)]),
      email: new FormControl('', [Validators.required,Validators.email]),
      confirm_email: new FormControl('', [Validators.required,Validators.email]),
      password: new FormControl('', [Validators.required]),
      confirm_password:new FormControl('',[Validators.required]),
      address: new FormControl('',[Validators.required]),
      province: new FormControl('',[Validators.required]),
      phone: new FormControl('', [Validators.required])
    })


  }


  provinces: string[] = [ // Lista de provincias de España
    "Álava", "Albacete", "Alicante", "Almería", "Asturias", "Ávila",
    "Badajoz", "Baleares", "Barcelona", "Burgos", "Cáceres", "Cádiz",
    "Cantabria", "Castellón", "Ceuta", "Ciudad Real", "Córdoba", "Cuenca",
    "Girona", "Granada", "Guadalajara", "Guipúzcoa", "Huelva", "Huesca",
    "Jaén", "La Coruña", "La Rioja", "Las Palmas", "León", "Lérida",
    "Lugo", "Madrid", "Málaga", "Melilla", "Murcia", "Navarra",
    "Orense", "Palencia", "Pontevedra", "Salamanca", "Segovia",
    "Sevilla", "Soria", "Tarragona", "Santa Cruz de Tenerife",
    "Teruel", "Toledo", "Valencia", "Valladolid", "Vizcaya",
    "Zamora", "Zaragoza"
  ];

  registerClick() {
    this.loadingCreate=true;
    let data:any = {
      name: this.registerForm.get('name')?.value,
      email: this.registerForm.get('email')?.value,
      address: this.registerForm.get('address')?.value,
      province: this.registerForm.get('province')?.value,
      password: this.registerForm.get('password')?.value,
      phone: this.registerForm.get('phone')?.value,
    }

    if(this.registerForm.invalid || this.registerForm.get('email')?.value!=this.registerForm.get('confirm_email')?.value || this.registerForm.get('password')?.value!=this.registerForm.get('confirm_password')?.value){
      if(this.registerForm.get('name')?.invalid){
        this.toastr.error('La logitud del nombre no puedes ser menor a 3')
      }
      if(this.registerForm.get('email')?.invalid){
        this.toastr.error('El email no es válido')
      }
      if(this.registerForm.get('email')?.value!=this.registerForm.get('confirm_email')?.value){
        this.toastr.error('Los emails no coinciden')
      }
      if(this.registerForm.get('password')?.invalid){
        this.toastr.error('La contraseña no es válida')
      }
      if(this.registerForm.get('password')?.value!=this.registerForm.get('confirm_password')?.value){
        this.toastr.error('Las contraseñas no coinciden')
      }
      if(this.registerForm.get('address')?.invalid){
        this.toastr.error('Dirección no válida')
      }
      if(this.registerForm.get('provincia')?.invalid){
        this.toastr.error('Debe seleccionar una provincia')
      }
      this.loadingCreate = false;
    } else {
      this.userService.create(data).subscribe({
        next: (response) => {
          this.toastr.success(response.message)
          if(response.data){
            this.login()
          }
          this.loadingCreate = false;
        },
        error: (err) => {
          this.toastr.error(err.error.message)
          console.log(err.error.message);
          this.loadingCreate = false;
        }
      })
    }
  }

  login() {
    this.authService.signIn({
      email: this.registerForm.get('email')?.value,
      password: this.registerForm.get('password')?.value,
    }).subscribe({
      next: (data: Response<User>) => {
        if (data.data) {
          this.authStateService.setUser(data.data);
          this.router.navigate(['/dashboard/works']);
        } else {
          this.authStateService.setUser(null);
        }
      },
      error: (data) => {
        this.toastr.error(data.error.message);
      },
      complete: () => {

      },
    })
  }

  togglePasswordVisibility1(): void {
    this.showPassword1 = !this.showPassword1;
  }
  togglePasswordVisibility2(): void {
    this.showPassword2 = !this.showPassword2;
  }

}
