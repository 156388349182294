<section class="font-roboto bg-bg-dark h-screen animate-fade-in">
  <div class="p-4 flex justify-end">
    <a routerLink="/login" class="text-primary font-roboto text-md">Volver</a>
  </div>
  <div class="px-4 pt-6 mx-auto md:h-screen lg:py-0">
    <h1 class="text-xl text-primary font-anton">Registro</h1>
    <div class="w-full md:mt-0 sm:max-w-lg xl:p-0 animate-fade-in-up">
      <div class="py-12 space-y-4 md:space-y-6 sm:p-8">
        <form (ngSubmit)="registerClick()" [formGroup]="registerForm" class="flex flex-col gap-4">
          <div class="mb-6">
            <input formControlName="name" type="text" name="name" id="name" appAuthFormInput placeholder="Nombre" required="">
          </div>
          <div class="mb-10">
            <span appAuthFormLightLabel>Teléfono</span>
            <input formControlName="phone" type="text" name="phone" id="phone" appAuthFormInput placeholder="Teléfono"
                   required="">
          </div>
          <div>
            <input formControlName="email" type="email" name="email" id="email" appAuthFormInput placeholder="Email" required="">
          </div>
          <div class="mb-6">
            <input formControlName="confirm_email" type="email" name="email" id="confirm-email" appAuthFormInput placeholder="Confirmar email" required="">
          </div>
          <div class="flex">
            <input formControlName="password" [type]="showPassword1 ? 'text' : 'password'" name="password" id="password" placeholder="Contraseña" appAuthFormInput required>
            <!-- Botón para alternar visibilidad de la contraseña -->
            <span (click)="togglePasswordVisibility1()" class="px-2 border-b border-lightGray outline-none text-gray-light flex justify-center items-center cursor-pointer">
              <!-- Icono de ojo -->
              <svg *ngIf="!showPassword1"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="text-gray-light w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
              <svg *ngIf="showPassword1"  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="text-gray-light w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>
            </span>
          </div>
          <div class="flex mb-6">
            <input formControlName="confirm_password" [type]="showPassword2 ? 'text' : 'password'" name="confirmPassword" id="confirm-password" placeholder="Confirmar contraseña" appAuthFormInput required>
            <!-- Botón para alternar visibilidad de la confirmación de contraseña -->
            <span (click)="togglePasswordVisibility2()" class="px-2 border-b border-lightGray outline-none text-gray-light flex justify-center items-center cursor-pointer">
              <!-- Icono de ojo -->
              <svg *ngIf="!showPassword2"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="text-gray-light w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
              <svg *ngIf="showPassword2"  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="text-gray-light w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>
            </span>
          </div>
          <div>
            <input formControlName="address" type="text" name="address" id="address" appAuthFormInput placeholder="Dirección" required="">
          </div>
          <div class="mb-6">
            <select formControlName="province" type="text" name="province" id="province" appAuthFormInput required="">
              <option value="">Provincia</option>
              <option *ngFor="let province of provinces" [value]="province">{{ province }}</option>
            </select>
          </div>
          <button [disabled]="loadingCreate" type="submit" class="w-full border-2 border-gray-light text-gray-light hover:opacity-85 focus:ring-4 focus:outline-none focus:ring-primary rounded-xl text-md px-5 py-3 text-center ">
            Registrarme
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
