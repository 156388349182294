<div>
  <app-loader *ngIf="loading" [size]="'large'"></app-loader>
</div>
<div *ngIf="!loading" class="flex flex-col bg-bg-light text-black px-4 h-[110vh] py-6">
  <div class="pt-6">
    <div *ngIf="work" class="flex justify-start items-center font-anton text-3xl">
      {{work.web_job.name}}
    </div>
  </div>
  <div class="pt-4">
    <div *ngIf="work" class="flex justify-start items-center font-anton text-xl">
      {{work.workplace.name}} - {{work.event.name}}
    </div>
  </div>
  <div class="mt-4">
    <p class="text-2xl font-anton">Fecha</p>
    <div class="flex ml-2 justify-start items-center font-semibold">
      <div class="mr-1 my-1">
        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 9H21M17 13.0014L7 13M10.3333 17.0005L7 17M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
      </div>
      <div>
        <span *ngIf="start_date === end_date">
          {{ start_date }}
        </span>
        <span *ngIf="start_date !== end_date">
          {{ start_date }} a {{ end_date }}
        </span>
      </div>
    </div>
  </div>
  <div class="mt-4">
    <p class="text-2xl font-anton">Salario</p>
    <div *ngIf="work" class="flex ml-2 justify-start items-center font-semibold">
      € {{work.salary}}/hora
    </div>
  </div>
  <div class="mt-4">
    <p class="text-2xl font-anton">Turnos</p>
    <div *ngFor="let shift of shifts" class="mt-2 font-semibold">
      <p class="font-anton font-light text-xl mx-2">Turno de {{ formatHour(shift.start_hour) }} a {{ formatHour(shift.end_hour) }}</p>
      <p class="mx-6"><strong>Necesitamos:</strong> {{ shift.capacity }} personas</p>
      <p class="mx-6"><strong>Huecos libres:</strong> {{ shift.freeSlots }}</p>
      <p class="mx-6"><strong>Salario:</strong> € {{ shift.salary }}/hora</p>
    </div>
  </div>
  <div *ngIf="tags" class="mt-4">
    <p class="text-2xl font-anton">Detalles</p>
    <ul>
      <li *ngFor="let tag of tags" class="font-semibold">- {{tag.name}}</li>
    </ul>
  </div>
  <div class="mt-4">
    <p class="text-2xl font-anton">Descripción</p>
    <div *ngIf="work">
      <p class="font-semibold">{{work.description}}</p>
    </div>
    <div *ngIf="work" class="mt-1">
      <p class="font-bold mb-1">Trabajo de {{work.web_job.name}}</p>
      <p class="font-semibold">{{work.web_job.description}}</p>
    </div>
  </div>
  <div class="-mx-4 px-4 mt-4 mb-16 bg-bg-dark text-gray-light">
    <p class="pt-4 text-2xl font-anton">Localización</p>
    <p *ngIf="work" class="flex my-2 font-semibold">
      <svg class="mr-1" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z" stroke="#FCFCFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="#FCFCFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
      {{work.workplace.location}}
    </p>
    <div *ngIf="work" class="flex justify-center mb-16">
      <div class="mb-16 w-full sm:w-5/6 md:w-3/4 lg:w-1/2 h-full sm:h-5/6 md:h-3/4 lg:h-1/2 overflow-hidden rounded-2xl">
        <div [innerHTML]="sanitizedMapIframe" class="w-full"></div>
      </div>
    </div>
  </div>
  <div *ngIf="showButton" class="fixed bottom-20 right-1 w-full p-4">
    <button class="w-full text-lg py-3 rounded-full shadow-lg font-roboto"
      [ngClass]="{
        'bg-primary text-black': !checkUserShiftsInWork(),
        'bg-bg-dark text-gray-light border border-gray-light p-1': checkUserShiftsInWork(),
        'bg-[#37d6e3] text-gray-dark': checkUserWorkApplicated()
      }"
      [disabled]="checkUserShiftsInWork() || checkUserWorkApplicated()"
      (click)="applyToWork()"
    >
      <span *ngIf="!checkUserShiftsInWork() && !checkUserWorkApplicated() && !loading">Inscribirme</span>
      <span *ngIf="checkUserShiftsInWork() && !checkUserWorkApplicated() && !loading">Inscrito</span>
      <span *ngIf="!checkUserShiftsInWork() && checkUserWorkApplicated() && !loading">Pendiente</span>
      <app-loader size="extra-small" *ngIf="loading"></app-loader>
    </button>
  </div>
</div>
